import $ from 'jquery';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

let $modal = $( '[data-modal]' );

$( () => {
   const updateModal = ( hash ) => {
    if ( hash == '' ) {
      $( 'body' ).removeClass( 'no-scroll' )
    }

    if (  hash && hash.startsWith( '#!' )  ) {
      if (  hash !== '#!'  ) {
        $( 'body' ).addClass( 'no-scroll' );
      } else {
        $( 'body' ).removeClass( 'no-scroll' );
      }
    }
  }

  if ( $modal.length ) {
    $( window ).on( 'hashchange', function() {
      updateModal( location.hash );
    });
    
    updateModal( location.hash );
  }
  //this is so the modal is initially hidden on page load
  setTimeout( () => {
    $( $modal ).addClass( 'visible' );
  }, 100 )
});