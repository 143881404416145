import $ from 'jquery';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import html2pdf  from 'html2pdf.js';


if( $( '.share-vision' ).length ) {

  $( '.share-vision' ).on( 'click', () => {
    let element = document.getElementById('my-vision');
    let opt = {
      margin:       [ 0, 0, 0, 0 ],
      filename:     'Hiebert Cabinets Vision Board.pdf',
      image:        { type: 'jpeg', quality: 1 },
      jsPDF:        { unit: 'in', format: 'letter', orientation: 'portrait' },
    };
    
    html2pdf().set( opt ).from( element ).save();

    window.dataLayer.push( {'event': 'pdf_download', 'value': 'download_by_email'} );
  } )
}