import $ from 'jquery';
import lozad from 'lozad';

$( function() {
  if ( $( '#gallery-grid' ).length ) {

    let itemsPerPage = 24;

    // get URL parameter
    const getUrlParameter = ( name ) => {
      let searchParams = new URLSearchParams( window.location.search );
      return searchParams.get( name );
    };

    // parse page parameter
    const parsePageParameter = () => {
      let pageParam = getUrlParameter( 'page' );
      return pageParam ? parseInt( pageParam ) : 1;
    };

    let currentPage = parsePageParameter();
    let currentFilter = getUrlParameter( 'filter' ) || 'all';

    // render gallery
    const renderGallery = ( page, filter ) => {
      let start = ( page - 1 ) * itemsPerPage;
      let end = start + itemsPerPage;

      let filteredGallery = galleryData.filter( image => {
        if ( filter === 'all' ) {
          return true;
        }
        return image.tags.includes( filter );
      });

      let pagedGallery = filteredGallery.slice( start, end );
      $( '#gallery-grid' ).empty();
      pagedGallery.forEach( image => {
        let imageContainer = `
          <div class="image-container ${ image.tags.join( ' ' ) }">
            <img class="lozad zoomable" data-src="${ image.url }" alt="${ image.alt }" data-caption="${ image.caption }" data-key="${ image.key }">
          </div>
        `;
        $( '#gallery-grid' ).append( imageContainer );
      });

      renderPagination( filteredGallery.length, page );
      lozad().observe(); // init lazyload on render
    };

    // render pagination
    const renderPagination = ( totalItems, page ) => {
      let totalPages = Math.ceil( totalItems / itemsPerPage );
      $( '#pagination-list' ).empty();

      if ( totalPages <= 1 ) return;

      // add previous button
      if ( page > 1 ) {
        let prevItem = `
          <li class="pagination-item">
            <a href="#" data-page="${ page - 1 }"><</a>
          </li>
        `;
        $( '#pagination-list' ).append( prevItem );
      }

      // add first page
      let firstItem = `
        <li class="pagination-item ${ page === 1 ? 'active' : '' }">
          <a href="#" data-page="1">1</a>
        </li>
      `;
      $( '#pagination-list' ).append( firstItem );

      // add pages around the current page
      if ( page > 2 ) {
        let start = Math.max( 2, page - 1 );
        let end = Math.min( totalPages - 1, page + 1 );

        if ( start > 2 ) {
          $( '#pagination-list' ).append( '<li class="pagination-item ellipsis">...</li>' );
        }

        for ( let i = start; i <= end; i++ ) {
          let paginationItem = `
            <li class="pagination-item ${ i === page ? 'active' : '' }">
              <a href="#" data-page="${ i }">${ i }</a>
            </li>
          `;
          $( '#pagination-list' ).append( paginationItem );
        }

        if ( end < totalPages - 1 ) {
          $( '#pagination-list' ).append( '<li class="pagination-item ellipsis">...</li>' );
        }
      } else {
        for ( let i = 2; i <= Math.min( 4, totalPages - 1 ); i++ ) {
          let paginationItem = `
            <li class="pagination-item ${ i === page ? 'active' : '' }">
              <a href="#" data-page="${ i }">${ i }</a>
            </li>
          `;
          $( '#pagination-list' ).append( paginationItem );
        }

        if ( totalPages > 4 ) {
          $( '#pagination-list' ).append( '<li class="pagination-item ellipsis">...</li>' );
        }
      }

      // add last page
      if ( totalPages > 1 ) {
        let lastItem = `
          <li class="pagination-item ${ page === totalPages ? 'active' : '' }">
            <a href="#" data-page="${ totalPages }">${ totalPages }</a>
          </li>
        `;
        $( '#pagination-list' ).append( lastItem );
      }

      // add next button
      if ( page < totalPages ) {
        let nextItem = `
          <li class="pagination-item">
            <a href="#" data-page="${ page + 1 }">></a>
          </li>
        `;
        $( '#pagination-list' ).append( nextItem );
      }
    };

    // update URL parameter
    const updateURLParameter = ( param, paramVal ) => {
      let url = new URL( window.location );
      url.searchParams.set( param, paramVal );

      window.history.replaceState( { path: url.href }, '', url.href );
    };

    // filter tag click event
    $( '#filter-tags .tag a' ).on( 'click', function( e ) {
      e.preventDefault();

      currentFilter = $( this ).parent().data( 'view' );
      currentPage = 1;

      $( '#filter-tags .tag' ).removeClass( 'active' );
      $( this ).parent().addClass( 'active' );

      updateURLParameter( 'filter', currentFilter );
      updateURLParameter( 'page', currentPage );
      renderGallery( currentPage, currentFilter );
    });

    // pagination click event
    $( '#pagination-list' ).on( 'click', '.pagination-item a', function( e ) {
      e.preventDefault();

      currentPage = parseInt( $( this ).data( 'page' ) );

      updateURLParameter( 'page', currentPage );
      renderGallery( currentPage, currentFilter );

      // scroll to top of gallery
      $( 'html, body' ).animate( {
        scrollTop: $( '#main-content' ).offset().top
      }, 150 );
    });

    // close slider function
    const closeSlider = () => {
      $( 'body' ).removeClass( 'no-scroll' );
      $( '.overlay' ).fadeOut( function() {
        $( this ).remove();
      });
      $( '.image-caption' ).remove();
      $( '.close-slider' ).remove();
      $( '.custom-slider' ).fadeOut();
    };

    // image click event to initialize slider
    $( '#gallery-grid' ).on( 'click', '.image-container', function( e ) {
      e.stopPropagation(); // prevent event bubbling
      $( 'body' ).toggleClass( 'no-scroll' );
      $( '<div class="overlay"></div>' ).appendTo( 'body' ).fadeIn();
      $( '<div class="close-slider">&times;</div>' ).appendTo( 'body' ).fadeIn();

      initializeSlider( $( this ).index() );
    });

    // overlay and close button click event to close slider
    $( 'body' ).on( 'click', '.overlay, .close-slider, .slider-inner', function() {
      closeSlider();
    });

    // ensure correct filter active on init
    $( '#filter-tags .tag' ).removeClass( 'active' );
    $( `#filter-tags .tag[data-view="${ currentFilter }"]` ).addClass( 'active' );

    // init based on URL parameters
    renderGallery( currentPage, currentFilter );

    // init slider
    const initializeSlider = ( startIndex ) => {
      let currentIndex = startIndex;
      $( '#slider-inner' ).empty();

      // Add images to the slider
      $( '#gallery-grid .image-container' ).each( function() {
        let img = $( this ).find( 'img' );
        let slide = `
          <div class="slider-slide">
            <img src="${ img.data( 'src' ) }" alt="${ img.attr( 'alt' ) }">
            <div class="slider-caption">${ img.data( 'key' ) } ${ img.data( 'caption' ) }</div>
          </div>
        `;
        $( '#slider-inner' ).append( slide );
      });

      // Show the slider
      $( '.custom-slider' ).fadeIn();

      // Show the current slide
      showSlide( currentIndex );

      // Handle next and previous buttons
      $( '.slider-button-next' ).off( 'click' ).on( 'click', function() {
        currentIndex = ( currentIndex + 1 ) % $( '#slider-inner .slider-slide' ).length;
        showSlide( currentIndex );
      });

      $( '.slider-button-prev' ).off( 'click' ).on( 'click', function() {
        currentIndex = ( currentIndex - 1 + $( '#slider-inner .slider-slide' ).length ) % $( '#slider-inner .slider-slide' ).length;
        showSlide( currentIndex );
      });
    };

    // Show a specific slide
    const showSlide = ( index ) => {
      $( '#slider-inner .slider-slide' ).hide();
      $( '#slider-inner .slider-slide' ).eq( index ).fadeIn();
    };
  }
});