import $ from 'jquery';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

if( $( '.vision-gallery' ).length ) {
  gsap.registerPlugin( ScrollTrigger );
  let VBimages = document.querySelectorAll( '.vision-gallery .image-container' );
  let leftRow = Math.round( $( VBimages ).length / 3 );
  let rightRow = leftRow * 2;
  let min = 0;
  let max = 300;
  let mm = gsap.matchMedia()

  // Random Placement Functions
  function negRandom() {
    return Math.floor( -( Math.random() * (max - min) + min ) )
  }
  function posRandom() {
    return Math.floor( Math.random() * (max - min) + min )
  }

  function getRandom() {
    return Math.floor( Math.random() * (100 - ( -100 ) ) + ( -100 ) )
  }

  // SHOW MORE BUTTON FUNCTIONALITY
  let showMoreText = $( '.show-more-text' );
  let StyleInfoHeight = $( '.style-info' ).length ? $( '.style-info' )[0].scrollHeight : '';

  // Mobile
  mm.add( '(max-width: 960px)', () => {
    // Initial Fade In
    gsap.fromTo( VBimages, { opacity: 0 }, { opacity: 1, delay: 0.5, duration: 0.5, stagger: 0.05 } )

    // Making sure accordion is closed
    $( '.style-info' ).css( 'max-height', '0' )
    $( showMoreText ).text( 'Show More' )
    $( '.show-more' ).removeClass( 'open' )
    $( '.style-info' ).removeClass( 'open' )
  } )

  $( '.show-more' ).on( 'click', function() {
    let $content = $( '.style-info' );

    $( '.style-info' ).css( 'max-height', '0' )

    if ($content.hasClass( 'open' ) ) {
      $( this ).removeClass( 'open' )
      $( showMoreText ).text( 'Show More' )
      $content.removeClass( 'open' );
    } else {
      $( this ).addClass( 'open' )
      $( showMoreText ).text( 'Show Less' )
      $content.addClass( 'open' );
      $content.css( 'max-height', StyleInfoHeight );
    }
  } )

  // Desktop
  mm.add( '(min-width: 960px)', () => {
    // Refresh ScrollTriggers
    ScrollTrigger.refresh();

    // Remove inline style from mobile view
    $( '.style-info' ).attr( 'style' , '' )

    // Initial Fade In
    gsap.fromTo( VBimages, { opacity: 0 }, { opacity: 1, delay: 0.5, duration: 0.5, stagger: 0.05 } )

    // Randomly place images
    VBimages.forEach( ( elem, index ) => {
      let x;
      let y;
      let z = getRandom();
      let iteration = index + 1;
      let width = $( elem ).data( 'width' );
      let height = $( elem ).data( 'height' );
      let aspectRatio = width / height;
  
      if( iteration < leftRow ) {
        x = negRandom();
      } else if ( iteration > rightRow ) {
        x = posRandom();
      } else {
        x = getRandom();
      }
  
      if( iteration < leftRow || iteration > rightRow ) {
        y = getRandom();
      } else {
        y = negRandom();
      }
      
      gsap.set( elem, { x: x, y: y, z: getRandom(), aspectRatio: aspectRatio } )
    } )

    // Grid images on load
    gsap.to( VBimages, {
      x: 0,
      y: 0,
      z: 0,
      duration: 1,
      delay: 2
    } )

    // Pinning Info Section
    ScrollTrigger.create({
      trigger: $( '.design-style-info' ),
      pin: true,
      start: 'top-=25 top',
      endTrigger: '.style-content',
      end: 'bottom bottom',
      onLeave: () => { $( '.style-content' ).addClass( 'complete' ) }
    })
  } )


  // Vision Board LocalStorage
  function arrayAdd( id, src ) {
    visionBoard.push( { number: id, source: src } )
  }
  
  function arrayRemove( id ) {
    visionBoard = visionBoard.filter( item => item.number !== id )
  }
  
  function addToLocalStorage( key, arr ) {
    localStorage.setItem( key, JSON.stringify( arr ) )
  }

  function initPageToggles() {
    visionBoard.forEach( ( elem ) => {
      let images = document.querySelector( `[data-id='${ elem.number }']` )
      $( images ).toggleClass( 'added' )
      $( images ).find( '.selected' ).toggleClass( 'hide' )
    } )
  }

  function addToVisionBoard() {
    let visionBoardContainer = document.querySelector( '.vision-board-container' );

    if ( !visionBoard || visionBoard.length == 0 ) {
      visionBoardContainer.innerHTML= '<h2 class="headline-4 center">No Images Selected</h2>'
    } else {
      visionBoardContainer.innerHTML= ''
    
      visionBoard.forEach( ( elem ) => {
        visionBoardContainer.innerHTML +=
        `
        <div class="image-container selected-vision-images" data-id="${ elem.number }">
          <a href="${ elem.source }" class="vision-button expand"></a>
          <button class="vision-button remove"></button>
          <img src="${ elem.source }" alt="">
        </div>
        `
      } )
    }
  }

  let visionBoard = !localStorage.images ? [] : JSON.parse( localStorage.images );
  
  $( VBimages ).on( 'click', function() {
    let imageSrc = $( this ).find( 'img' ).attr( 'src' )
    let imageID = $( this ).data( 'id' )
    let addedProp = $( this ).find( '.selected' )
  
    $( this ).hasClass( 'added' ) ? arrayRemove( imageID ) : arrayAdd( imageID, imageSrc );
    $( this ).toggleClass( 'added' )
    $( addedProp ).toggleClass( 'hide' )
  
    addToLocalStorage( 'images', visionBoard );
  } )

  $( '.my-vision-board' ).length ? addToVisionBoard() : '';
  $( '.vision-gallery .gallery' ).length ? initPageToggles() : '';

  let removeVBImages = $( '.selected-vision-images .remove' );

  $( removeVBImages ).on( 'click', function() {
    let VBimageContainer = $( this ).parent();
    let imageID = $( VBimageContainer ).data( 'id' )

    arrayRemove( imageID )
    addToLocalStorage( 'images', visionBoard );

    $( VBimageContainer ).fadeOut( 500 );
    // $( VBimageContainer ).slideUp( 500 );
  } )
}

if( $( '.show-more' ).length ) {
  
}