import $ from 'jquery';

const navToggle = () => {
  $( '.nav' ).toggleClass( 'open' )
  $( 'body' ).toggleClass( 'no-scroll' )
}

$( window ).on( 'load', () => {
  $( '.nav' ).addClass( 'active' )
  $( '.nav-handle' ).on( 'click', () => {
    navToggle()
  } )
});

$( window ).on( 'resize', () => {
  if( $( '.nav' ).hasClass( 'open' ) ) {
    navToggle()
  }
});