import $ from 'jquery';
import lightGallery from 'lightgallery';
import lgZoom from 'lightgallery/plugins/zoom'

if( $( '#lightgallery' ).length  ) {
  lightGallery(document.getElementById('lightgallery'), {
    plugins: [lgZoom],
    licenseKey: '16281FE2-62F0-42E9-B381-A654A0B2DC50',
    selector: '.expand'
  });
}
