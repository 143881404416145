import $ from 'jquery';

function setDiscoveryID( value ) {
  localStorage.setItem( 'discoveryID', value )
}

function toggleSteps() {
  $( '.step-container' ).toggleClass( 'hide-step' )
}

function toggleStepTwoCards( id ) {
  $( '.step-two-cards' ).addClass( 'hide' )
  $( `[data-${id}]` ).removeClass( 'hide' )
}

if( $( '.template-vision-board' ).length ) {
  let startDiscovery = document.querySelector( '.start-discovery' )

  $( startDiscovery ).on( 'click', () => {
    setDiscoveryID( null )
  } )
}

if( $( '.discovery-questions' ).length || $( '.discovery-buttons' ).length ) {
  let stepOneCard = document.querySelectorAll( '[data-id]' );
  let discoveryID = localStorage.getItem( 'discoveryID' );
  let stepOneButton = document.querySelector( '.back-step-one' )
  let startAgain = document.querySelector( '.start-again' )

  $( stepOneCard ).on( 'click', function() {
    let cardID = $( this ).data( 'id' );
    setDiscoveryID( cardID )

    toggleSteps();
    toggleStepTwoCards( cardID );
  })

  $( stepOneButton ).on( 'click', () => {
    toggleSteps();
    setDiscoveryID( null )
  } )

  $( startAgain ).on( 'click', () => {
    setDiscoveryID( null )
  } )

  if( !discoveryID || discoveryID == 'null' ) {
    setDiscoveryID( null )
  } else {
    toggleSteps();
    toggleStepTwoCards( discoveryID );
  }
}